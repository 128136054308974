document.addEventListener("DOMContentLoaded", function (event) {
  /**
   * Menu toggle
   */
  const toggle = document.querySelector(".c-header__toggle");
  const header = document.querySelector(".c-header");
  const headerLinks = document.querySelectorAll(".c-menu__link");
  const wpadminbar = document.querySelector("#wpadminbar");
  const body = document.body;

  if (toggle && header) {
    toggle.addEventListener("click", (e) => {
      e.preventDefault();
      header.classList.toggle("c-header--show-menu");
    });
  }

  if (window.innerWidth <= 768) {
    if (headerLinks) {
      headerLinks.forEach((el) => {
        el.addEventListener("click", () => {
          header.classList.remove("c-header--show-menu");
        });
      });
    }
  }

  /**
   * Fixed menu
   */

  const fixedMenu = () => {
    const header = document.querySelector(".js-header");
    if (header) {
      const doc = document.documentElement;
      const checkScroll = () => {
        let curScroll = window.scrollY || doc.scrollTop;

        if (curScroll > 46) header.classList.add("is-fixed");
        else header.classList.remove("is-fixed");
      };

      window.addEventListener("scroll", checkScroll);
    }
  };

  fixedMenu();

  /**
   * Submenu
   */

  const submenuOpen = document.querySelectorAll(".js-submenu-click");
  if (submenuOpen) {
    submenuOpen.forEach((el) => {
      el.addEventListener("click", function (e) {
        // e.preventDefault();
        console.log(e);
        this.classList.toggle("active");
        this.classList.toggle("open-submenu");
      });
    });
  }

  /**
   * Footer tabs
   */
  const cFooterHeaderMenus = document.querySelectorAll(
    ".c-footer__header--menu"
  );
  if (cFooterHeaderMenus) {
    cFooterHeaderMenus.forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        const cFooterSingleAdditional = e.currentTarget.closest(
          ".c-footer__single-additional"
        );
        if (cFooterSingleAdditional) {
          cFooterSingleAdditional.classList.toggle(
            "c-footer__single-additional--show"
          );
        }
      });
    });
  }

  /* Simple paralax image  */

  const paralaxImage = () => {
    const images = document.querySelectorAll(".animated-image-js");
    if (images) {
      images.forEach((el) => {
        new simpleParallax(el, {
          overflow: false,
          delay: 0.2,
          orientation: "up",
          maxTransition: 100,
          scale: 1.2,
        });
      });
    }
  };

  paralaxImage();

  /* hiden/show header belt */
  const checkedHeader = () => {
    (function () {
      var doc = document.documentElement;
      var w = window;

      var prevScroll = w.scrollY || doc.scrollTop;
      var curScroll;
      var direction = 0;
      var prevDirection = 0;

      var header = document.querySelector(".js-header");

      var checkScroll = function () {
        /*
         ** Find the direction of scroll
         ** 0 - initial, 1 - up, 2 - down
         */

        curScroll = w.scrollY || doc.scrollTop;
        if (curScroll > prevScroll) {
          //scrolled up
          direction = 2;
        } else if (curScroll < prevScroll) {
          //scrolled down
          direction = 1;
        }

        if (direction !== prevDirection) {
          if (header) toggleHeader(direction, curScroll);
        }

        if (curScroll < 10) {
          header.classList.remove("header-hidden");
          header.classList.remove("show-top-belt");
        }

        prevScroll = curScroll;
      };

      var toggleHeader = function (direction, curScroll) {
        let startScroll = 2;
        if (direction === 2 && curScroll > startScroll) {
          header.classList.add("header-hidden");
          header.classList.remove("show-top-belt");
          prevDirection = direction;
        } else if (direction === 1) {
          header.classList.remove("header-hidden");
          header.classList.add("show-top-belt");
          prevDirection = direction;
        }
      };

      window.addEventListener("scroll", checkScroll);
    })();
  };

  checkedHeader();

  var disableSubmit = false;
  jQuery('input.wpcf7-submit[type="submit"]').click(function () {
    // jQuery(':input[type="submit"]').attr('value', "Sending...")
    if (disableSubmit == true) {
      return false;
    }
    disableSubmit = true;
    return true;
  });

  var wpcf7Elm = document.querySelector(".wpcf7");
  if (wpcf7Elm) {
    wpcf7Elm.addEventListener(
      "wpcf7submit",
      function (event) {
        // jQuery(':input[type="submit"]').attr('value', "send")
        disableSubmit = false;
      },
      false
    );
  }
});
